import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import { plus, getApi, getWarehouseInfo, getOrderPendingToPrint, getOrders, getOrdersWarehouse, noDataLoading, printLabelApi, printGuiaApi,sendToTweeAPI } from './Helper.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import props from 'prop-types';



import $ from 'jquery';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';

import {Link,useParams} from "react-router-dom";

import '../assets/css/sb-admin-2.min.css';
import '../assets/css/App.css';

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function Orders() {

  const parameterPage = useParams();
  const [respPrintDate,setRespPrintDate] = useState(true);
  const [respCall,setResponse] = useState(true);
  const [respPrint,setResPrint] = useState(true);
  const [warehouse,setWarehouse] = useState(true);
  const [warehouseName,setWarehouseName] = useState(true);
  const [respPrintGuia,setResPrintGuia] = useState(true);
  
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  
  const queryParams = new URLSearchParams(window.location.search)
  const searchQuery = queryParams.get("search")
  const fieldsHeader = []
  const fieldsBody = []

  const [statesPrinter,setStatesPrinter] = useState(true);

  useEffect(() => {
    document.title = "Pedidos pendientes - 4Norte"
    setStatesPrinter({currentPrinter: 0,totalPrinter: 0});
  }, []);

  // PAUSE PROCESS
  const elInput = document.querySelector('#pausePrint');
  let pause = Promise.resolve();
  const sleep = ms => new Promise(r => setTimeout(r,ms));

  const pauseClick = cb => 
    elInput.addEventListener('click', cb, {once: true});

  function checkForPause() {   
    pauseClick(() => {
      pause = new Promise(resolve => {
        pauseClick(() => { resolve(); checkForPause();});
      });
    });
  }
  // END PAUSE PROCESS

  let page = 1;
  let idWarehouse = 0;

  const refreshData = (page,idWarehouse) => 
  {

    console.log(parameterPage);
      const refreshConst = async () => {
        setLoading1(true);

        if (parameterPage.page==undefined)
          page = 1;
        else
          page = parameterPage.page;

        if (parameterPage.idWarehouse==undefined)
          idWarehouse = 0;
        else
          idWarehouse = parameterPage.idWarehouse;

        console.log("BODEGA: "+idWarehouse);
        console.log("PAGE: "+page);

        setWarehouse(idWarehouse);

        setLoading2(true)
        setWarehouseName(await getWarehouseInfo(cookies.get('public_token'),idWarehouse));
        setLoading2(false)

        if (idWarehouse==0)
          setResponse(await getOrders(page,cookies.get('public_token')))
        else
          setResponse(await getOrdersWarehouse(idWarehouse,page,cookies.get('public_token')))
        setLoading1(false);
      };
      refreshConst();
  };
  useEffect(refreshData,[]);

  useEffect(() => {
    refreshData(page,idWarehouse)
      // ... write code to get new data using new prop, also update your state
  }, [parameterPage.idWarehouse]);

  const printLabel = async (refresh=true,idOrder=null) => {
    setLoading1(true);
    setResPrint(await printLabelApi(idOrder))
    setLoading1(false);
    if (refresh==true)
    {
      refreshData()
    }
  }
  // printLabel()

  const sendToTwee = async (idOrder=null) => {
    setLoading1(true);
    setResPrintGuia(await sendToTweeAPI(idOrder));
    setLoading1(false);
  }

  const printGuia = async (refresh=true,idOrder=null) => {
    setLoading1(true);
    setResPrintGuia(await printGuiaApi(idOrder))
    setLoading1(false);

    if (refresh==true)
    {
      refreshData()
    }
  };
  // const printGuia = (refresh=true,idOrder=null) => 
  // {


  //     // printGuia()

  // };

  const printBoth = async (idOrder) =>
  {
    return await printGuia(false,idOrder).then(function(response){
      printLabel(false,idOrder);
    });
  }
  const sendTwee = async (idOrder) =>
  {
    window.open('https://wms.digitag.cl/4nv2/api/label/'+idOrder);
    window.open('https://wms.digitag.cl/4nv2/api/label/guia/'+idOrder);

    return await sendToTwee(idOrder).then(function(response){
    });
  }

  const printRange = async () => {
    // setLoading1(true);


    setRespPrintDate(await getOrderPendingToPrint(
                                  document.getElementById('date_from').value,
                                  document.getElementById('date_to').value,
                                  warehouse,
                                  1,
                                  cookies.get('public_token')).then(function(response){
                                    let counter = 0;

                                    setStatesPrinter({currentPrinter:counter,totalPrinter:response.count})

                                    if (response.count>0)
                                    {
                                      response.result.map((r) => {
                                        printBoth(r.id_order).then(function(response2){
                                          counter++;
                                          setStatesPrinter({currentPrinter:counter,totalPrinter:response.count})
                                        })
                                      })
                                    }
                                  })
                    )


  };
  const exportRange = async () => {
    // setLoading1(true);

    const from = document.getElementById('date_from').value;
    const to = document.getElementById('date_to').value;
    

    // window.open("/print/bulkpdf.php?from="+from+"&to="+to+"&idWarehouse="+warehouse+"&type=label",'_blank');
    // window.open("/print/bulkpdf.php?from="+from+"&to="+to+"&idWarehouse="+warehouse+"&type=guia",'_blank');
    window.open("https://wms.digitag.cl/4nv2/api/label2/submitprocess/"+from+"/"+to+"/"+warehouse+"/1",'_blank');
    // window.open("https://wms.digitag.cl/4nv2/api/label2/submitprocess/"+from+"/"+to+"/"+warehouse+"/1",'_blank');
    // setRespPrintDate(await getOrderPendingToPrint(
    //                               document.getElementById('date_from').value,
    //                               document.getElementById('date_to').value,
    //                               warehouse,
    //                               1,
    //                               cookies.get('public_token')).then(function(response){
    //                                 let counter = 0;
    //                                 // setStatesPrinter({currentPrinter:counter,totalPrinter:response.count})
    //                                 // if (response.count>0)
    //                                 // {
    //                                 //   response.result.map((r) => {
    //                                 //     printBoth(r.id_order).then(function(response2){
    //                                 //       counter++;
    //                                 //       setStatesPrinter({currentPrinter:counter,totalPrinter:response.count})
    //                                 //     })
    //                                 //   })
    //                                 // }
    //                               })
    //                 )


  };
  const exportRangeNoTwee = async () => {
    // setLoading1(true);

    const from = document.getElementById('date_from').value;
    const to = document.getElementById('date_to').value;
    

    // window.open("/print/bulkpdf.php?from="+from+"&to="+to+"&idWarehouse="+warehouse+"&type=label",'_blank');
    // window.open("/print/bulkpdf.php?from="+from+"&to="+to+"&idWarehouse="+warehouse+"&type=guia",'_blank');
    // window.open("https://wms.digitag.cl/4nv2/api/label2/submitprocess/"+from+"/"+to+"/"+warehouse+"/0",'_blank');
    window.open("https://wms.digitag.cl/4nv2/api/label2/submitprocess/"+from+"/"+to+"/"+warehouse+"/0",'_blank');
    // setRespPrintDate(await getOrderPendingToPrint(
    //                               document.getElementById('date_from').value,
    //                               document.getElementById('date_to').value,
    //                               warehouse,
    //                               1,
    //                               cookies.get('public_token')).then(function(response){
    //                                 let counter = 0;
    //                                 // setStatesPrinter({currentPrinter:counter,totalPrinter:response.count})
    //                                 // if (response.count>0)
    //                                 // {
    //                                 //   response.result.map((r) => {
    //                                 //     printBoth(r.id_order).then(function(response2){
    //                                 //       counter++;
    //                                 //       setStatesPrinter({currentPrinter:counter,totalPrinter:response.count})
    //                                 //     })
    //                                 //   })
    //                                 // }
    //                               })
    //                 )


  };

    // getRange()

  // const printRange = () =>
  // {
  // }
  // async function run(respPrintDate) {
  //   respPrintDate.result.map((r) => {

  //     await sleep(1000);
  //     counter++;
  //     setStatesPrinter({currentPrinter: counter,totalPrinter: respPrintDate.total_result})
  //     await pause;

  //   });
  // }

  // useEffect(refreshData,[]);

  const Loading = () => {
    return (
    <>
        <div className="row">
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
          <div className="col-md-12 mb-2">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
                <div className="col-md-4 mb-4">
                  <Skeleton count={1}/>
                </div>
              </div>
          </div>
        </div>
    </>
    );
  };

  const ShowData = () => {

    
    
    if (respCall.total_result <= 0)
    {
      return (
        <>
          {<Loading />}
        </>
      );
    }
    

    return (
      <>
        <div className="table-responsive">
          <table className="table table-borderless mb-0" id="dataTable" width="100%" cellspacing="0" role="grid" aria-describedby="dataTable_info">
            <thead className="border-bottom">
              <tr role="row">
                <th>Bodega</th>
                <th>#</th>
                <th>Customer</th>
                <th>Código Externo</th>
                <th>Status</th>
                <th>Fecha Creación</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
            {respCall.result.map((r) => {
              return (
                <>
                  <tr className="border-bottom">
                    <td>{r['warehouse_name']}</td>
                    <td>{r['id_order']}</td>
                    <td>{r['code']}</td>
                    <td>{r['id_order_external']}</td>
                    <td>{r['name']}</td>
                    <td>{r['date_add']}</td>
                    <td className="d-none">
                      <Link to={`/products/own/view/${r.id_product_available}`} className="btn btn-primary btn-sm btn-sm">
                        View detail
                      </Link>
                    </td>
                    <td>
                      <a href={`https://wms.digitag.cl/4nv2/api/label/${r.id_order}`} target="_blank">
                        LABEL
                      </a>
                      &nbsp;|&nbsp;
                      <a href={`https://wms.digitag.cl/4nv2/api/label/guia/${r.id_order}`} target="_blank">
                        GUIA
                      </a>
                    </td>
                  </tr>
                </>
              );
            })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const ShowPagination = (parameters) => {

    const pageNumbers = [];

    for (let i = 1; i <= parameters.totalPages; i++){
      pageNumbers.push(i);
    }
 
    return (
      <>
        <ul className="pagination">
          {pageNumbers.map((number) => {
            return (
              <>
                  <li className="paginate_button page-item ${number == parameters.currentPage ? 'active':''}" key="pagination_{number}">

                    <Link to={parameterPage.idUrl==undefined?`/orders/warehouse/0/${number}`:`/orders/warehouse/url/${parameterPage.idUrl}/${number}`} onClick={() => refreshData(number)} className="page-link" key="pag{number}">{number}</Link>
                  </li>
              </>
            );
          })}
        </ul>
      </>
    );
  };


  // const notify = () => toast("Wow so easy !");


  return (
    <>
      <div id="layoutSidenav">
        <Header />
        <Navbar {...statesPrinter}/>
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="file"></i></div>
                                        Bodega / {loading2? "..." : warehouseName.total_result==0?"TODO WMS":(warehouseName.result[0].name+" ("+(loading1 ? '' :respCall.total_result)+")") }
                                    </h1>
                                    <div className="page-header-subtitle">Realiza la gestión de los pedidos WMS</div>
                                </div>
                                <div className="col-12 col-xl-auto mt-4"></div>
                            </div>
                        </div>
                    </div>
                </header>
                
                
                <div className="container-xl px-4 mt-n10">
                <div className="row">
                   {warehouse==0?(""):
                   (
                    <>
                      <div className="col-md-6 d-none">
                        <div className="card shadow mb-4">
                          <div className="card-header py-3">
                              <h6 className="m-0 font-weight-bold text-primary">Impresora GUIA</h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <select className="form-control form-select">
                                  <option>Print 1 (10.00.00.10)</option>
                                  <option>Print 2 (10.00.00.20)</option>
                                  <option>Print 3 (10.00.00.30)</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 d-none">
                        <div className="card shadow mb-4">
                          <div className="card-header py-3">
                              <h6 className="m-0 font-weight-bold text-primary">Impresora ETIQUETA</h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <select className="form-control form-select">
                                  <option>Print 1 (10.00.00.10)</option>
                                  <option>Print 2 (10.00.00.20)</option>
                                  <option>Print 3 (10.00.00.30)</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card shadow mb-4">
                          <div className="card-header py-3">
                              <h6 className="m-0 font-weight-bold text-primary">Realización de Corte {loading2? "..." : warehouseName.total_result==0?" ":(warehouseName.result[0].name+" / Pendientes "+warehouseName.result[0].pending_print+"") }</h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 d-none">
                                <label>Limite de impresión</label>
                                <select className="form-control form-select">
                                  <option>50</option>
                                  <option>100</option>
                                  <option>150</option>
                                  <option>200</option>
                                  <option>300</option>
                                </select>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Fecha Desde</label>
                                  <input className="form-control" name="date_from" id="date_from" type="date"/>
                                </div>
                                <div className="form-group mb-2">
                                  <label>Fecha Hasta</label>
                                  <input className="form-control" name="date_to" id="date_to" type="date"/>
                                </div>
                                <div className="row">
                                  <div className="col-md-12 mt-2 mb-3 d-none">
                                    <input className="exportAll" id="exportAll" name="exportAll" type="checkbox" checked="checked"/> <label for="exportAll">Enviar a Twee</label>
                                  </div>
                                  <div className="col-md-12 mt-2">
                                    <span className="btn btn-primary btn-sm btn-block" onClick={() => exportRange()}>EXPORTAR RANGO Y ENVIAR A TWEE</span>
                                  </div>
                                  <div className="col-md-12 mt-2">
                                    <span className="btn btn-warning btn-sm btn-block" onClick={() => exportRangeNoTwee()}>SOLO EXPORTAR RANGO (NO ENVIARA A TWEE)</span>
                                  </div>
                                </div>
                                <div className="row mt-2 d-none">
                                  <div className="col-md-12">
                                    <span className="btn btn-primary btn-sm btn-block" onClick={() => printRange()}>CORTE / IMPRIMIR Y ENVIAR A TWEE</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card shadow mb-4">
                          <div className="card-header py-3">
                              <h6 className="m-0 font-weight-bold text-primary">Impresión Unitaria</h6>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-4">
                                  <label>ID WMS</label>
                                  <input className="form-control" name="external_code" id="external_code" type="text"/>
                                </div>
                                <div className="row">
                                  <div className="col-md-6 mb-4">
                                    <span className="btn btn-primary btn-sm btn-block d-none" onClick={() => printGuia(false,document.getElementById('external_code').value)} >Print Guía</span>
                                  </div>
                                  <div className="col-md-6 mb-4">
                                    <span className="btn btn-primary btn-sm btn-block d-none" onClick={() => printLabel(false,document.getElementById('external_code').value)} >Print Label</span>
                                  </div>
                                </div>
                                <span className="btn btn-primary btn-sm btn-block mb-5 d-none" onClick={() => printBoth(document.getElementById('external_code').value)} >Imprimir Ambos</span>
                                <span className="btn btn-primary btn-sm btn-block mb-5" onClick={() => sendTwee(document.getElementById('external_code').value)} >ENVIAR A TWEE</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                 }
                    

                    
                  
                </div>
                  


                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Listado de pedidos no impresos /  {loading2? "..." : warehouseName.total_result==0?" ":(warehouseName.result[0].name+" / Pendientes "+respCall.total_result+"") }</h6>
                    </div>
                    <div className="card-body">
                      {loading1? <Loading /> : <ShowData /> }
                      {loading1 ? '' : <ShowPagination totalPages={respCall.total_page} currentPage={respCall.current_page} /> }
                    </div>
                  </div>
                </div>
            </main>
        </div>

        
      </div>
    </>
  );
}

export default Orders;
