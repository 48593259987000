import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Cookies from 'universal-cookie';
import { getWarehouses } from './Helper.js'

const cookies = new Cookies();

const Navbar = ({currentPrinter,totalPrinter}) => 
{

	const [warehouses,setWarehouses] = useState(true);
	const [loading,setLoading] = useState(true);

	const refreshData = () => 
	{
	    const refreshConst = async () => {
	    
	    	setLoading(true);
    		setWarehouses(await getWarehouses(cookies.get('public_token')))
	    	setLoading(false);
	      
	    };
	    refreshConst();
	};
	useEffect(refreshData,[]);

	const ShowWarehouses = () =>
	{
		return (<>
				{warehouses.result.map((r) => {
						return (<>
							<Link className="nav-link collapsed" to={`/orders/warehouse/${r.id_warehouse}`} data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
							    <div className="nav-link-icon"><i data-feather="grid"></i></div>
									{r.name}
							    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
							</Link>
						</>)
					})
				}
				</>);
	}

	return (
		<div id="layoutSidenav_nav">
		    <nav className="sidenav shadow-right sidenav-light">
		        <div className="sidenav-menu">
		            <div className="nav accordion" id="accordionSidenav">
		                <div className="sidenav-menu-heading">Core</div>
		                <a className="nav-link collapsed" href="https://wms.digitag.cl/4nv2/front" target="_BLANK" data-bs-toggle="collapse" data-bs-target="#collapseDashboards" aria-expanded="false" aria-controls="collapseDashboards">
		                    <div className="nav-link-icon"><i data-feather="activity"></i></div>
		                    Acceso a WMS
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </a>
		                <div className="collapse" id="collapseDashboards" data-bs-parent="#accordionSidenav">
		                    <nav className="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
		                        <a className="nav-link" href="dashboard-1.html">
		                            Default
		                            <span className="badge bg-primary-soft text-primary ms-auto">Updated</span>
		                        </a>
		                        <a className="nav-link" href="dashboard-2.html">Multipurpose</a>
		                        <a className="nav-link" href="dashboard-3.html">Affiliate</a>
		                    </nav>
		                </div>
		                <div className="sidenav-menu-heading">PEDIDOS</div>
		                <Link className="nav-link collapsed" to="/orders/warehouse/0" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Últimos pedidos
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <div className="sidenav-menu-heading">Cortes</div>
		                <Link className="nav-link collapsed" to="/exports" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
		                    <div className="nav-link-icon"><i data-feather="grid"></i></div>
		                    Cortes realizados
		                    <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
		                </Link>
		                <div className="sidenav-menu-heading">BODEGAS</div>
	                    {loading? "..." : <ShowWarehouses /> }
		                <div className="sidenav-menu-heading">COLA DE IMPRESION</div>
		                <a class="nav-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages" href="/orders/pending">
		                	<div class="nav-link-icon"><i data-feather="grid"></i></div>
	                		<span className="currentPrinter" id="currentPrinter">{currentPrinter}</span> / <span className="totalPrinter" id="totalPrinter">{totalPrinter}</span>
	                		<br />
	                		<br />
	                		<span className="pausePrint d-none" id="pausePrint">PAUSE</span>
		                </a>
		            </div>
		        </div>
		        <div className="sidenav-footer">
		            <div className="sidenav-footer-content">
		                <div className="sidenav-footer-subtitle">Logged in as:</div>
		                <div className="sidenav-footer-title">{cookies.get('username')}
		                	<span className="sidebar-logout">
		                		<Link to="/logout">(Logout)</Link>
	                		</span>
	                	</div>
		            </div>
		        </div>
		    </nav>
		</div>
	)
}
export default Navbar;