import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
// import Skeleton from "react-loading-skeleton";
import { plus, getApi, Authentication } from './Helper.js'
import $ from 'jquery';

import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';


import {Link,useParams,BrowserRouter,useNavigate,redirect} from "react-router-dom";



import '../assets/css/sb-admin-2.min.css';
import '../assets/css/App.css';

// DECLARE COOKIES
const cookies = new Cookies();
// END DECLARE COOKIES

function Auth() {
  
  const [respCall,setResponse] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
     document.title = "Auth - 4N"
  }, []);
  document.body.classList.add('bg-primary');

  const handleSubmit = async (e) => {
      e.preventDefault();
      const elements = e.target.elements;
      setResponse(await Authentication(elements.username.value,elements.password.value))
  };

  useEffect(()=> {

    if (respCall.total_result==1)
    {
      cookies.set('public_token', respCall.result[0].access_token, { path: '/' });
      cookies.set('username', respCall.result[0].username, { path: '/' });
      // navigate("/products")
      window.location.replace('/orders/warehouse/0');
    }
    else
    {
      cookies.set('public_token', null, { path: '/' })
    }

  },[respCall]);

  return (

    <>
      <div id="layoutAuthentication">
          <div id="layoutAuthentication_content">
              <main>
                  <div className="container-xl px-4">
                      <div className="row justify-content-center">
                          <div className="col-lg-5">
                          <div className="row">
                            <div className="col-lg-12 mt-4">
                              <h3 className="fw-light my-4 text-center logo-auth">4NORTE WMS</h3>
                            </div>
                          </div>
                              <div className="card shadow-lg border-0 rounded-lg mt-5">
                                  <div className="card-body">
                                      <form onSubmit={handleSubmit} method="POST">
                                          <div className="mb-3">
                                              <label className="small mb-1" htmlFor="inputEmailAddress">Username</label>
                                              <input className="form-control" id="inputEmailAddress" type="text" placeholder="Enter username" name="username" />
                                          </div>
                                          <div className="mb-3">
                                              <label className="small mb-1" htmlFor="inputPassword">Password</label>
                                              <input className="form-control" id="inputPassword" type="password" placeholder="Enter password" name="password"/>
                                          </div>
                                          <div className="mb-3">
                                              <div className="form-check">
                                                  <input className="form-check-input" id="rememberPasswordCheck" type="checkbox" value="" />
                                                  <label className="form-check-label" htmlFor="rememberPasswordCheck">Remember password</label>
                                              </div>
                                          </div>
                                          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                              <a className="small hidden" href="#">Forgot Password?</a>
                                              <button className="btn btn-primary btn-block" type="submit">LOGIN</button>
                                          </div>
                                      </form>
                                  </div>
                                  <div className="card-footer text-center hidden">
                                      <div className="small"><a href="#">Need an account? Sign up!</a></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </main>
          </div>
          <div id="layoutAuthentication_footer">
              <footer className="footer-admin mt-auto footer-dark">
                  <div className="container-xl px-4">
                      <div className="row">
                          <div className="col-md-6 small">Copyright &copy; 4Norte 2022-2023</div>
                          <div className="col-md-6 text-md-end small">
                              <a href="#!">Privacy Policy</a>
                              &middot;
                              <a href="#!">Terms &amp; Conditions</a>
                          </div>
                      </div>
                  </div>
              </footer>
          </div>
      </div>
    </>
  );
}

export default Auth;
