import React, { useState, useEffect } from "react";
import {Link,useParams} from "react-router-dom";
import Navbar from './Navbar.js';
import Header from './Header.js';
import Footer from './Footer.js';

export function plus(a, b)
{
  return a + b;
}
export function getApi(endpoint,token)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': token}
        };
    const response = await fetch("https://wms.digitag.cl/4nv2/api/v1/"+endpoint,requestOptions);
    return await response.json();
  };
  return getProducts();
}
export function getApiNormal(endpoint,token)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': token}
        };
    const response = await fetch("https://wms.digitag.cl/4nv2/api/v1/"+endpoint,requestOptions);
    return await response;
  };
  return getProducts();
}
export function putApi(endpoint,token,data)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'PUT',
            body: data,
            headers: { 'Authorization': token}
        };
    const response = await fetch("https://wms.digitag.cl/4nv2/api/v1/"+endpoint,requestOptions);
    return await response.json();
  };
  return getProducts();
}
export function postApi(endpoint,token,data,customEndPoint=false)
{
  const getProducts = async () => {
    const requestOptions = {
            method: 'POST',
            body: data,
            headers: { 'Authorization': token}
        };
    if (customEndPoint==true)
    {
       endpoint = endpoint;
    }
    else
    {
      endpoint = "https://wms.digitag.cl/4nv2/api/v1/"+endpoint;
    }

    const response = await fetch(endpoint,requestOptions).then(function(response) {
        return response.json();
    })


    // return await response.json();
  };
  return getProducts();
}

export function postToPrint(endpoint,token,data,customEndPoint=false)
{
  const requestOptions = {
          method: 'POST',
          body: data,
          headers: { 'Authorization': token}
      };
  if (customEndPoint==true)
  {
     endpoint = endpoint;
  }
  else
  {
    endpoint = "https://wms.digitag.cl/4nv2/api/v1/"+endpoint;
  }
  
  return new Promise((resolve, reject) => {
                  fetch(endpoint,requestOptions)
                    .then(resolve)
                    .catch(reject)
                });

}
export function putMap(accessToken,data)
{
  return putApi("admin/fields/update/"+accessToken,accessToken,data);
}
export function printLabelApi(idOrder)
{
  return postToPrint("/print/label.php?id_order="+idOrder,"","",true);
}
export function printGuiaApi(idOrder)
{
  return postToPrint("/print/guia.php?id_order="+idOrder,"","",true);
}
export function sendToTweeAPI(idOrder)
{
  return postToPrint("/print/twee.php?id_order="+idOrder,"","",true);
}

export function Authentication(user,password)
{
  return getApi("login/user/"+user+"/"+password,null);
}

export function noDataLoading()
{
  return (<>No information available</>)

}



export function getOrderReadyToProcess(page,accessToken)
{
  return getApi("orders/ready_to_process/"+page+"/"+accessToken,accessToken);
}
export function getOrderPendingToPrint(from,to,idWarehouse,page,accessToken)
{
  return getApi("orders/pending/"+page+"/"+from+"/"+to+"/"+idWarehouse+"",accessToken);
}
export function exportRangeLabel(from,to,idWarehouse,page,accessToken)
{
  return getApi("/print/bulkpdf.php?from="+from+"&to="+to+"&idWarehouse="+idWarehouse+"&type=label",accessToken);
}
export function exportRangeGuia(from,to,idWarehouse,page,accessToken)
{
  return getApi("/print/bulkpdf.php?from="+from+"&to="+to+"&idWarehouse="+idWarehouse+"&type=guia",accessToken);
}
export function getOrders(page,accessToken)
{
  return getApi("orders/"+page+"/"+accessToken,accessToken);
}
export function getOrdersWarehouse(idWarehouse,page,accessToken)
{
  return getApi("orders/warehouse/"+page+"/"+accessToken+"/"+idWarehouse,accessToken);
}
export function getWarehouses(accessToken)
{
  return getApi("warehouses",accessToken);
}
export function getWarehouseInfo(accessToken,idWarehouse)
{
  return getApi("warehouses/info/"+accessToken+"/"+idWarehouse,accessToken);
}



